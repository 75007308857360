.rules {
  &-box {
    background-color: rgba(var(--bg-rules-box), .6);
    border-radius: .5rem;

    padding: clamp(20px, 2.5vw, 40px);
  }
  &-container {
    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }
  &-head {
    color: rgba(var(--clr-r), 1);

    font-weight: 500;
    font-size: var(--t-base);
  }
  &-list {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;

    margin-bottom: 30px;

    &__item {
      color: rgba(var(--clr-w), 1);
      font-weight: 500;
      font-size: clamp(var(--t-sm), 1vw, var(--t-base));
    }
  }
  &-info {
    color: rgba(var(--clr-rules-text), 1);
    font-weight: 500;
    font-size: clamp(var(--t-sm), 1vw, var(--t-base));
    line-height: 150%;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}
