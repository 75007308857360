@mixin font-face($font-family, $url, $weight, $style) {
  @font-face {
    font-style: $style;
    font-weight: #{$weight};
    font-family: "#{$font-family}";

    font-display: swap;
    src: url("../fonts/#{$url}.woff2") format("woff2"),
    url("../fonts/#{$url}.woff") format("woff");
  }
}
@include font-face("Codec Pro", "CodecPro-News", 300, normal);
@include font-face("Codec Pro", "CodecPro-Bold", 500, normal);
@include font-face("Codec Pro", "CodecPro-ExtraBold", 800, normal);

@include font-face("Gilroy", "Gilroy-Regular", 400, normal);
@include font-face("Gilroy", "Gilroy-Medium", 500, normal);
@include font-face("Gilroy", "Gilroy-SemiBold",600, normal);
@include font-face("Gilroy", "Gilroy-Bold", 700, normal);



// 100 – Thin || Hairline
// 200 – Extra Light || Ultra Light || Thin
// 300 – Light
// 400 – Normal || Regular
// 500 – Medium
// 600 – Semi Bold || Demi Bold
// 700 – Bold
// 800 – Extra Bold || Ultra Bold || Heavy
// 900 – Black || Heavy
