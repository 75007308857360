.lang {
  background-color: rgba(var(--clr-dark-8), 1);

  position: relative;

  border-radius: 6px;

  &:hover {
    .lang-hidden {
      transform: scale(1);
      opacity: 1;
      visibility: visible;
    }
    .lang-arrow {
      transform: scaleY(-1);
    }
  }

  &-selected {
    padding: 10px 14px 10px 10px;

    display: flex;
    align-items: center;
    grid-gap: 11px;
  }
  &-text {
    color: rgba(var(--clr-w), 1);
    font-size: 13px;
    font-weight: 800;
    text-transform: uppercase;
  }
  &-arrow {
    display: block;
    width: 7px;
    height: 4px;
    clip-path: polygon(50% 100%, 0 0, 100% 0);

    background-color: #424454;

    transition: transform 400ms ease;
  }
  &-hidden {
    position: absolute;

    z-index: 2;

    padding-top: clamp(.5rem, 1vw, 1rem);

    top: 100%;
    left: 0;
    width: 100%;

    transition: all 300ms ease;

    transform: scale(.925);

    opacity: 0;

    visibility: hidden;
  }
  &-list {
    background-color: rgba(var(--clr-dark-8), 1);
    border: 1px solid rgba(var(--clr-w), .1);

    overflow: hidden;

    border-radius: 4px;

    &__item {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      padding: 12px 10px;

      cursor: pointer;

      transition: background-color 400ms ease;

      &:hover {
        background-color: rgba(var(--clr-10), .15);
      }
    }
  }
  &-flag {
    width: 1.5rem;
    height: 1rem;

    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}

@include laptop {
.lang {
    grid-area: lang;

    &-text,
    &-arrow {
      display: none;
    }
    &-selected {
      padding: 0;

      width: 40px;
      height: 32px;

      justify-content: center;
    }
    &-flag {
      max-width: 24px;
      width: 100%;
      height: auto;

      img {
        width: inherit;
        height: inherit;
      }
    }
    &-list {

      &__item {
        padding: 8px 0;

        @include dfc;
      }
    }
    &-hidden {
      width: 100%;
    }
  }
}
