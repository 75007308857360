.fight {
  --bg-item: 46, 18, 71;
  --box-grid-gutter: clamp(24px, 2.5vw, 40px);
  --bg-main-item: 21, 22, 34;
  --bg-box: #{HexToRGB(#17121b)};

  margin-bottom: clamp(60px, 5vw, 80px);

  &-row {
    display: flex;
    flex-wrap: nowrap;
    grid-gap: clamp(30px, 3.125vw, 50px);
    flex-shrink: 0;
    &__image {

      img {
        width: 146px;
        height: 100px;
        display: block;

        object-fit: contain;

        @include tablet {
          width: 100px;
          height: 80px;
        }
      }
    }
  }
  &-header {
    background-color: #16121b;
    border-radius: 6px;

    min-height: 90px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 40px;

    padding: 0 clamp(1rem, 2vw, 2rem);

    &__flex {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-gap: 1rem;
    }
    &__return {
      color: rgba(var(--clr-r), 1);
      font-size: var(--t-base);
      font-weight: 600;

    }
    &__rounds {
      display: flex;
      align-items: center;
      grid-gap: clamp(24px, 3.375vw, 54px);

      line-height: 150%;

      flex: 1;

      &-amount {
        color: rgba(var(--clr-r), 1);
        font-size: var(--t-lg);
        font-weight: 800;
      }
    }
    &__head {
      color: rgba(var(--clr-w), 1);
      font-size: var(--t-base);
      font-weight: 600;
    }
    &__row {
      display: flex;
      align-items: center;
      grid-gap: 10px;

      flex: 1;

      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      &-item {
        flex: 0 0 auto;
        img {
          width: 74px;
          height: 74px;

          display: block;
        }
      }
    }
  }
  &__container {
    max-width: calc(1834px + (var(--container-offset) * 2));
  }
  &-box {
    background-color: rgba(var(--bg-box), .6);

    border-radius: 4px;

    padding: clamp(20px, 2.125vw, 34px) clamp(14px, 2.125vw, 34px);
    &__items {
      background-color: #19141d;

      padding: clamp(12px, 2vw, 32px);

    }
    &__grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(174px, 1fr));
      grid-gap: clamp(1rem, 1.875vw, 30px);

      &-data {
        flex: 1;
      }
      &-name {
        font-size: clamp(var(--t-sm), 1vw, var(--t-base));
        color: rgba(var(--clr-w), 1);
        font-weight: 700;
        line-height: 150%;

        @include hide-text(2);
      }
      &-price {
        font-size: clamp(var(--t-sm), 1vw, var(--t-base));
        color: rgba(var(--clr-r), 1);
        font-weight: 700;
        line-height: 150%;

        @include hide-text(1);
      }
      &-image {
        height: 84px;
        width: 120px;

        img {
          width: inherit;
          height: inherit;

          object-fit: contain;

          display: block;
        }
      }
      &-item {
        background-image: linear-gradient(to top, rgba(var(--bg-item), 1), rgba(var(--clr-w), 0.025) 100%);

        border-radius: 6px;

        border-bottom: 2px solid rgba(var(--clr-ppl), 1);

        padding: 20px;

        min-height: 204px;

        @include dfc;
        grid-gap: 26px;
        flex-direction: column;
      }
      &-data {
        text-align: center;
      }
    }
    &__flex {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: var(--box-grid-gutter);
    }
    &__banner {
      min-height: 258px;

      border-radius: 4px;

      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: top left;

      @include tablet {
        min-height: 150px;
      }

      &--row {
        background-image: url('../img/fight-page/bg-fight-roulette.webp');

        @include dfc;
        background-color: #17121b;

        overflow: hidden;
      }
    }
  }
}

@include tablet {
  .fight {
    &-header {
      padding: 1rem;
      &__rounds {
        width: 100%;
      }
      &__row {
        &-item {
          img {
            width: 3rem;
            height: 3rem;
          }
        }
      }
      &__flex {
        align-items: flex-start;
        flex-direction: column;
      }
    }
    &-box {
      &__flex {
        grid-template-columns: 100%;
      }
    }
  }
}
