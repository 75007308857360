.menu {
  &__flex {
    display: flex;
    align-items: center;
  }
  &__icon {
    svg {
      fill: rgba(var(--clr-dark-3), 1);
      width: 24px;
      height: 24px;

      transition: fill 400ms ease;

      display: block;
    }
  }
  &-burger {
    display: none;
    flex-direction: column;
    align-items: center;
    grid-gap: 8px;

    padding: 10px;

    &__icon {
      width: 24px;
      height: 20px;

      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-direction: column;
      grid-gap: 5px;

      position: relative;

      &-bar {
        display: block;
        width: 100%;
        height: 3px;

        transition: transform 300ms ease, width 500ms ease;

        position: absolute;

        border-radius: 4px;

        background-color: rgba(var(--clr-dark-3), 1);

        &:nth-child(1) {
          width: 75%;
          top: 5px;
          right: 0;
        }
        &:nth-child(2) {
          bottom: 5px;
          left: 0;
          width: 100%;
        }
      }
    }
    &--active {
      .menu-burger__icon-bar {
        width: 100%;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(var(--rotate));

        &:nth-child(2) {
          --rotate: 45deg;
        }
        &:nth-child(1) {
          --rotate: -45deg;
        }
      }
    }
  }
  &__link {
    display: flex;
    align-items: center;
    grid-gap: 10px;

    padding: 0 23px;

    position: relative;

    @include hover {
      &::after {
        opacity: 1;
      }
      &::before {
        opacity: 1;
      }
    }
    &:before {
      @include pseudo;

      width: 100%;
      height: 100%;
      left: 0;
      top: 300%;
      box-shadow: 0 0 60px  rgba(var(--clr-r), .5);

      opacity: 0;

      transition: opacity 400ms ease;

      pointer-events: none;
    }
    &::after {
      @include pseudo;

      width: 100%;
      height: 2px;

      opacity: 0;

      background-color: rgba(var(--clr-r), 1);

      bottom: calc(-1 * (var(--header-h) / 2.65));
      left: 0;

      pointer-events: none;

      transition: all 400ms ease;
    }

    @include hover {
      .menu__icon {
        svg {
          fill: rgba(var(--clr-r), 1);
        }
      }
      .menu__link-text {
        color: rgba(var(--clr-w), 1);
      }
    }
    &-text {
      font-size: var(--t-base);
      text-transform: uppercase;
      font-weight: 700;

      transition: color 400ms ease;

      color: rgba(var(--clr-dark-3), 1);
    }
  }
}

@include desktop {
  .menu {
    &__link {
      padding: 0 10px;
    }
    &__icon {
      svg {
        width: 18px;
        height: 20px;
      }
    }
    &__link {
      &-text {
        font-size: 15px;
      }
    }
  }
}

@include laptop {
  .menu {
    position: fixed;

    bottom: 0;
    left: 0;

    width: 100%;

    z-index: 4;

    background-color: rgba(var(--clr-dark-7), 1);
    border-top: 1px solid rgba(var(--clr-r), .5);

    padding: 0;

    &-burger {
      display: flex;

      &:active {
        background-color: rgba(var(--clr-dark-5), .1);
      }
    }
    &__flex {
      display: grid;
      grid-template-columns: repeat(6,1fr);
    }
    &__link {
      flex-direction: column;

      text-align: center;

      padding: 10px;
      grid-gap: 8px;

      &:active {
        background-color: rgba(var(--clr-dark-5), .1);
      }

      svg, img {
        display: block;
      }

      &-text {
        @include hide-text(1);
        font-size: 9px;

        color: rgba(var(--clr-w), 1);
      }
    }
  }
}

@include small-tablet {
  .menu {
    &__flex {
      justify-content: space-between;
    }
    &__link {
      &-text {
        font-size: 8px;
        font-weight: 600;
      }
    }
  }
}
