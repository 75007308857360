.faq {
  --bg-header: #{HexToRGB(#221d2a)};
  --clr-text-info: #b4b0b7;
  $root-hidden-padding: 30px;
  --bg-arrow: #{HexToRGB(#16121c)};
  --arrow-ratio: 30px;
  --bg-content: #{HexToRGB(#151119)};

  margin: 0px 0 clamp(70px, 6vw, 96px) 0;

  &-body {
    max-width: 1166px;

    margin: 0 auto;
  }
  &-title {
    font-size: clamp(var(--t-xl), 1.75vw, var(--t-xxl-2));
    text-align: center;

    margin-bottom: 44px;
  }
  &-list {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;

    &__header {
      padding: 1.25rem 1.5rem 1.25rem 2.125rem;

      background-color: rgba(var(--bg-header), .4);

      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-gap: 1rem;
    }
    &__arrow {
      background-color: rgba(var(--bg-arrow), 1);

      transition: background-color 400ms ease;

      flex: 0 0 auto;

      width: var(--arrow-ratio);
      height: var(--arrow-ratio);

      border-radius: 6px;

      @include dfc;

      position: relative;

      &-bar {
        display: block;

        width: 8px;
        height: 1px;

        top: 52.5%;

        background-color: rgba(var(--clr-r), 1);

        position: absolute;

        transform: translateY(-50%) rotate(var(--rotate));

        transition: all 400ms ease;

        &:nth-child(1) {
          --rotate: 45deg;

          left: 8.5px;
        }
        &:nth-child(2) {
          --rotate: -45deg;

          right: 8.5px;
        }
      }
    }
    &__content {
      transition: all 300ms ease;

      display: flex;
      flex-direction: column;

      background-color: rgba(var(--bg-content), .4);

      transform-origin: bottom;
      transform: scale(.95);

      opacity: 0;

      padding: $test 60px $root-hidden-padding $root-hidden-padding;
    }
    &__hidden {
      max-height: 0;

      transition: all 500ms cubic-bezier(0.86, 0, 0.07, 1);

      overflow: hidden;
    }
    &__info {
      color: var(--clr-text-info);

      font-weight: 500;
      font-size: clamp(var(--t-sm), 1vw, var(--t-base));
      line-height: 140%;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    &__name {
      font-weight: 600;
      font-size: var(--t-base);

      color: rgba(var(--clr-w), 1);

      transition: color 400ms ease;
    }
    &__item {
      width: 100%;

      overflow: hidden;

      border-radius: .75rem;

      cursor: pointer;

      @include hover {
        .faq-list__name {
          color: rgba(var(--clr-r), 1);
        }
      }

    }
  }
}

.faq-list {
  &__item {
    &--active {
      .faq-list__name {
        color: rgba(var(--clr-r), 1);
      }
      .faq-list__content {
        opacity: 1;

        transform: scale(1);
      }
      .faq-list__arrow {
        background-color: rgba(var(--clr-r), 1);

        top: 50%;

        &-bar {
          background-color: rgba(var(--bg-arrow), 1);

          &:nth-child(1) {
            --rotate: -45deg;
          }
          &:nth-child(2) {
            --rotate: -135deg;
          }
        }
      }
    }
  }
}

@include mobile {
  .faq {
    &-list {
      &__header {
        padding: 1rem;
      }
      &__content {
        padding: 1rem;
      }
    }
  }
}
