.footer {
  --footer-pt: clamp(48px, 6.25vw, 100px);
  --footer-pb: clamp(20px, 1.75vw, 28px);
  --footer-inner-gap: clamp(48px, 7.5vw, 120px);
  --clr-footer-btn: #{HexToRGB(#201a26)};
  --footer-large-image-w: 920px;

  padding: var(--footer-pt) 0 var(--footer-pb) 0;

  position: relative;

  background-color: rgba(var(--bg-overlay), .5);

  z-index: 2;
  &__container {
    max-width: calc(1612px + (var(--container-offset) * 2));
  }
  &__body {
    display: flex;
    flex-direction: column;
    grid-gap: var(--footer-inner-gap);
  }
  &-logo {
    max-width: 10.5rem;
  }
  &__support {
    &-btn {
      grid-gap: 0.875rem;

      padding: 1rem 1.25rem;

      border-radius: .25rem;

      background-color: rgba(var(--clr-footer-btn), 1);

      position: relative;

      &::before {
        @include pseudo;

        width: 100%;
        height: 100%;

        border-radius: inherit;

        background-image: linear-gradient(to top, rgba(var(--clr-r), 1), transparent 100%);

        pointer-events: none;

        opacity: .1;
        bottom: 0;
        left: 0;

        transition: 300ms ease;
      }
      @include hover {
        &::before {
          opacity: .2;
        }
      }
    }
    &-text {
      font-size: var(--t-base);
      font-weight: 700;
      color: rgba(var(--clr-w, 1));

      position: relative;
    }
    &-icon {
      width: 1.5rem;
      height: 1.5rem;

      position: relative;
    }
  }
  &-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:nth-child(2) {
      position: relative;
      // &::before {
      //   @include pseudo;
      //   width: 100vw;
      //   left: -50vw;
      //   margin-right: -50vw;
      //   margin-left: 50%;
      //   max-width: 100vw;
      //   top: calc(50% );
      //   transform: translateY(-50%);
      //   height: 90px;
      //   background-color: rgba(var(--bg-body), 1);
      //   z-index: -1;
      // }
    }
  }
  &-rights {
    font-size: var(--t-base);
    color: rgba(var(--clr-w), 1);
    font-weight: 700;

    line-height: 140%;

    text-align: right;

    & > span {
      color: rgba(var(--clr-dark-3), 1);
      font-weight: 400;
      display: block;
    }
  }
  &__unsimple {
    @include dfc;
    grid-gap: 0.625rem;

    transition: 300ms ease;

    @include hover {
      filter: brightness(120%);
    }
    &-logo {
      width: 2.5rem;
      height: 2.5rem;
    }
    &-container {
      text-align: right;
    }
    &-text {
      display: block;

      color: rgba(var(--clr-dark-4), 1);

      font-weight: 500;
      font-size: var(--t-sm);
    }
  }
  &-floor {
    &__col {
      &_flex-col {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        grid-gap: 1rem;
      }
    }
  }
  &-image {
    position: absolute;
    width: 100%;
    max-width: var(--footer-large-image-w);
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);

    z-index: -1;

    img {
      display: block;
      height: 100%;
      width: 100%;
    }
  }
}

@include laptop {
  .footer {
    --footer-large-image-w: 800px;
  }
}

@include tablet {
  .footer {
    --footer-inner-gap: 2rem;
    --footer-pt: 3rem;

    &-rights {
      text-align: center;
    }
    &-logo {
      max-width: 7.5rem;
    }
    &-floor {
      &__col {
        justify-content: center;
        align-items: center;

        &_flex-col {
          max-width: 24rem;
          margin: 0 auto;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          align-items: center;
        }
      }
    }
    &-image {
      display: none;
    }
    &-flex {
      flex-direction: column;
      align-items: center;
      grid-gap: 1.25rem;
    }
  }
}
