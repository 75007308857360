.search-panel {

  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 74px;

  &__flex {
    background-color: rgba(var(--bg-overlay), 1);
    min-height: 100px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 clamp(20px, 1.875vw, 30px);
  }
  .search-bar {
    &__input {
      min-width: 350px;
    }
  }
}

@include laptop {
  .search-panel {
    .panel-filter {
      width: 100%;
    }
    .range {
      width: auto;
      flex: 1;
    }
    &__flex {
      padding: 1.5rem 1rem;

      flex-direction: column-reverse;
      grid-gap: 1.5rem;
    }
    &__bar {
      width: 100%;
    }
    .search-bar {
      min-width: auto;
      width: 100%;
    }
  }
}

@include tablet {
  .search-panel {
    .panel-filter {
      flex-wrap: wrap;
    }
    .range {
      width: 100%;
      flex: 1 0 auto;

      margin-top: 1rem;
    }
  }
}
