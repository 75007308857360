.contract {
  --bg-item: #{HexToRGB(#1a1620)};
  --neg-icon-margin: -3rem;
  $ticks: 10;
  $deg: 360;
  --bg-header: #{HexToRGB(#1d1721)};

  margin-bottom: 80px;
  &-header {
    min-height: 114px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 clamp(20px, 2.625vw, 42px);

    background-color: #16121b;

    border-radius: 4px;

    margin-bottom: clamp(60px, 6.25vw, 100px);

    &__col {
      display: flex;
      flex-direction: column;
      grid-gap: 6px;

      color: rgba(var(--clr-w), 1);
      font-size: clamp(var(--t-base), 1.125vw, var(--t-md));
      font-weight: 600;

      &--row {
        flex-direction: row;
      }
      &-highlighted {
        color: rgba(var(--clr-r), 1);
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
    &__total {
      color: rgba(var(--clr-dark-3), 1);

      span {
        font-size: clamp(var(--t-xxl), 1.75vw, var(--t-xxl-2));
        color: rgba(var(--clr-r), 1);
        font-weight: 800;
      }
    }
    &__flex {
      display: flex;
      align-items: center;
      grid-gap: clamp(20px, 2.5vw, 40px);
    }
  }
  .panel {
    &-grid {
      &__item {
        cursor: pointer;
      }
    }
  }
  &-wheel {
    height: 600px;

    aspect-ratio: 1 / 1;

    position: relative;

    &::before {
      @include pseudo;

      width: 100%;
      height: 100%;

      top: 50%;
      left: 50%;

      background-color: rgba(var(--clr-r), .01);

      border-radius: 50%;

      filter: blur(2rem);

      transform: translate(-50%, -50%);
    }

    &__items {
      position: relative;
      height: 100%;

      transform: rotate(18deg);
    }
    &__circle {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      img {
        pointer-events: none;
        display: block;
      }
    }
    &__container {
      height: 100%;

      position: relative;
    }
    &__icon {
      width: 100px;
      height: 200px;

      position: absolute;

      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);

      pointer-events: none;

      z-index: 1;

      img {
        display: block;
      }
    }
    &__info {
      position: relative;

      z-index: 2;

      text-align: center;
    }
    &__button {
      margin-bottom: 20px;

      width: 270px;
      height: 80px;

      z-index: 2;

      padding: 0 1rem;

      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url('../img/global/shape-button-overlay2x.webp');

      @include dfc;
      text-align: center;

      position: relative;

      & > span {
        font-size: 20px;
        font-weight: 800;
        text-transform: uppercase;
        color: rgba(var(--clr-w), 1);
      }
    }
    &__value {
      display: flex;
      align-items: center;
      grid-gap: 4px;

      font-size: var(--t-base);
      font-weight: 600;
    }
    &__amount,
    &__total {
      color: rgba(var(--clr-y), 1);
    }
    &__notif {
      font-size: var(--t-sm);
      font-weight: 500;
      color: rgba(var(--clr-r), 1);

      text-align: center;

      text-decoration: underline;
    }
    &__center {
      position: absolute;

      top: 50%;
      left: 50%;

      width: 270px;
      height: 270px;

      @include dfc;
      flex-direction: column;

      transform: translate(-50%, -50%);

      &::before {
        @include pseudo;

        width: 100%;
        height: 100%;

        border-radius: 50%;

        box-shadow: 0 0 3rem 2rem rgba(var(--clr-r), .2);

        pointer-events: none;
      }
    }
  }
  &-grid {
    margin: 0 auto clamp(60px, 6.25vw, 100px) auto;

    display: grid;
    grid-template-columns: .8fr 1fr;
    grid-gap: 1rem;

    max-width: 1620px;
  }
  &-inner {
    padding: clamp(32px, 3.5vw, 56px) 0 clamp(32px, 2.625vw, 42px) 0;

    background-color: #18141d;
  }
  &-steps {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: calc(.25rem + (-1 * var(--neg-icon-margin))) clamp(30px, 3.25vw, 52px);
    @include laptop {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: calc(.25rem + (-1 * var(--neg-icon-margin))) 24px;
    }
    @include mobile {
      display: flex;
      flex-direction: column;
    }

    &__item {
      background-color: rgba(var(--bg-item), 1);

      border-radius: .5rem;

      @include dfc;
      flex-direction: column;
      align-items: center;

      text-align: center;

      min-height: 188px;

      padding: 1rem;

      position: relative;

      &:not(:last-child) {
        &::after {
          @include pseudo;;

          width: 30px;
          height: 50px;

          top: 50%;
          transform: translateY(-50%);

          right: -28px;

          background-size: 100% 100%;
          background-image: url('../img/contract/arrow-image.webp');

          pointer-events: none;
        }
      }
    }
    &__info {
      color: rgba(var(--clr-w), 1);
      font-weight: 600;
      line-height: 140%;

      p {
        @include hide-text(2);
      }
    }
    &__link {
      color: rgba(var(--clr-r), 1);
      font-weight: 600;
      text-decoration: underline;
    }
    &__icon {
      width: clamp(70px, 5.625vw, 90px);
      height: clamp(70px, 5.625vw, 90px);

      margin: var(--neg-icon-margin) 0 32px 0;

      position: relative;

      &::before {
        @include pseudo;

        width: 150px;
        height: 100px;

        pointer-events: none;

        background-color: rgba(var(--clr-r), .1);

        filter: blur(20px);

        border-radius: 50%;

        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
      }

      svg, img {
        width: inherit;
        height: inherit;

        display: block;

        fill: rgba(var(--clr-r), 1);
        filter: drop-shadow(7px 12px 0 rgba(0, 0, 0, 0.07));
      }
    }
  }
}

@include laptop {
  .contract {
    margin-top: 0;
    &-grid {
      display: flex;
      flex-direction: column;
    }
    &-steps {
      &__item {
        &::after {
          width: 24px !important;
          right: -24px !important;
        }
        &:nth-child(3) {
          &::after {
            display: none;
          }
        }
      }
    }
    &-wheel {
      height: 400px;
      &__circle {
        img {
          object-fit: contain;
          height: 400px;

          margin: 0 auto;
        }
      }
    }
  }
}

@include tablet {
  .contract {
    &-header {
      flex-direction: column;
      grid-gap: 2rem;
      padding: 1.5rem 1rem;

      &__flex {
        width: 100%;
      }
      &__col {
        width: 100%;
      }
    }
    &-steps {
      grid-template-columns: repeat(2, 1fr);
      &__item {
        &::after {
          display: block !important;

          top: calc(100% - 10px) !important;
          left: 50% !important;
          transform: translateX(-50%) scaleX(-1) rotate(90deg) !important;
          right: initial !important;
        }
        &:nth-child(3) {
          &::after {
            display: none !important;
          }
        }
      }
    }
  }
}

@include mobile {
  .contract {
    &-steps {
      &__item {
        &:nth-child(3) {
          &::after {
            display: block !important;
          }
        }
      }
    }
    &-header {
      &__flex {
        flex-direction: column;
        grid-gap: 2rem;
      }
      &__col {
        &--row {
          flex-direction: column;
        }
      }
    }
    &-wheel {
      &__center {
        width: 200px;
        height: 200px;
      }
      &__button {
        width: 200px;
        height: 60px;

        span {
          font-size: var(--t-base);
        }
      }
    }
  }
}
