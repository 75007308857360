.open {
  &-actions {
    max-width: 300px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    grid-gap: 1.25rem;
  }
  &-multiplier {
    background-color: #1d141d;

    border-radius: 4px;

    width: 100%;

    display: flex;
    align-items: center;
    grid-gap: 1rem;
    justify-content: center;

    padding: 1.125rem 1.25rem;

    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1rem;

    &__item {
      background-color: #2a1f2b;

      border-radius: .25rem;

      text-align: center;

      font-size: 16px;
      font-weight: 800;

      color: rgba(var(--clr-w), 1);

      width: 34px;
      height: 34px;

      @include dfc;

      &--active {
        background-color: rgba(var(--clr-r), 1);

        filter: drop-shadow(0 0 1rem rgba(var(--clr-r), .75));
      }

      span {
        line-height: 100%;
      }
    }
  }
  &-button {
    min-width: 290px;
    height: 80px;

    @include dfc;
    text-align: center;

    filter: drop-shadow(0 0 1rem rgba(var(--clr-r), .5));

    font-size: 19px;
    font-weight: 800;
    text-transform: uppercase;
    color: rgba(var(--clr-w), 1);

    background-image: url('../img/global/shape-button-overlay2x.webp');

    background-size: 100% 100%;
    background-repeat: no-repeat;

    span {
      color: rgba(var(--clr-y), 1);
    }
  }
  &-roulette {
    margin-bottom: 80px;

    &__head {
      text-align: center;
      font-weight: 800;
      font-size: clamp(var(--t-xl), 1.75vw, var(--t-xxl-2));
      text-transform: uppercase;

      margin-bottom: 82px;

      &-zip {
        @include zip-text;
      }
      &-highlighted {
        position: relative;
        top: 2px;

        line-height: 100%;

        color: rgba(var(--clr-r), 1);
        font-size: clamp(25px, 1.875vw, 30px);
      }
    }
  }
  &-roulette {
    &__flex {
      display: flex;
      align-items: center;
      grid-gap: clamp(2rem, 3vw, 48px);

      flex-wrap: nowrap;
    }
    &__row {
      position: relative;
      min-height: 200px;

      margin-bottom: clamp(60px, 6.25vw, 100px);

      position: relative;

      &::before,
      &::after {
        @include pseudo;

        width: 100%;
        height: 100%;

        background-image: linear-gradient(to right, rgba(var(--bg-body), 1), transparent);

        top: 0;

        pointer-events: none;

        z-index: 1;
      }

      &::before {
        left: -500px;
      }
      &::after {
        right: -500px;
        transform: scaleX(-1);
      }

      @include dfc;

      &-item {
        flex: 0 0 auto;

        position: relative;

        &--active {
          z-index: 2;
          .open-roulette__drop-image {
            transform: translate(calc(-50% + 5.5rem), -50%) scale(1) !important;
            opacity: 1;

            filter: drop-shadow(0 0 .5rem rgba(var(--clr-r), 1));
          }
          .open-roulette__item-image {
            opacity: 0;
            transform: scale(.9);
          }
        }
      }
    }
    &__drop-image {
      width: 264px;
      height: 254px;

      transition: all 400ms ease;

      position: absolute;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(.9);

      opacity: 0;

      img {
        object-fit: contain;
        display: block;
        width: inherit;
        height: inherit;
      }
    }
    &__item {
      &-image {
        width: 124px;
        height: 90px;

        transition: all 400ms ease;

        img {
          object-fit: contain;
          display: block;
          width: inherit;
          height: inherit;
        }
      }
    }
    &__divider {
      position: absolute;

      left: 50%;
      transform: translateX(-50%);

      width: 400px;

      z-index: 2;

      @include mobile {
        width: 320px;
      }

      img {
        display: block;
      }
    }
  }
  &-content {
    max-width: 1700px;

    margin: 0 auto clamp(70px, 6.25vw, 100px) auto;

    &__head {
      color: rgba(var(--clr-w), 1);

      font-size: 23px;
      text-transform: uppercase;
      font-weight: 800;
      text-align: center;

      margin-bottom: 60px;
    }
    &__grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(244px, 1fr));
      grid-gap: clamp(24px, 2.875vw, 46px);

      @include mobile {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 4px;

        .open-content__grid-item {
          padding: 1rem .25rem;

          min-height: 160px;
        }
        .open-content__image {
          width: 80px;
          height: 60px;

          margin-bottom: 1rem;
        }
        .open-content__name {
          font-size: var(--t-sm);
        }
        .open-content__price {
          font-size: var(--t-sm);
        }
        .open-content__info {
          margin-bottom: .5rem;
        }
      }

      &-item {
        min-height: 17.5rem;

        border-radius: 6px;

        border-bottom: 3px solid rgba(var(--clr-ppl), 1);

        background-image: linear-gradient(to top, rgba(var(--clr-ppl), .2), rgba(var(--clr-w), .03) 100%);

        padding: 2rem 1rem;

        @include dfc;
        flex-direction: column;
      }
    }
    &__info {
      flex: 1;

      text-align: center;
    }
    &__name {
      color: rgba(var(--clr-w), 1);

      font-size: clamp(var(--t-base), 1.125vw, var(--t-md));
      font-weight: 700;
      margin-bottom: 4px;

      @include hide-text(1);
    }
    &__type {
      color: #988ea2;
      font-size: var(--t-sm);
      font-weight: 600;
    }
    &__price {
      color: rgba(var(--clr-w), 1);
      font-size: clamp(var(--t-base), 1.125vw, var(--t-md));
      font-weight: 700;
      text-align: center;
    }
    &__image {
      width: 136px;
      height: 100px;

      margin-bottom: 32px;

      img {
        width: inherit;
        height: inherit;
        object-fit: contain;
        display: block;
      }
    }
  }
}

@include laptop {
  .open {
    &-roulette {
      &__flex {
        grid-gap: 1.5rem;
      }
      &__drop-image {
        width: 220px;
        height: 120px;
      }
      &__row {
        &-item {
          &--active {
            .open-roulette__drop-image {
              transform: translate(calc(-50% + 3.25rem), -50%) scale(1.5) !important;
            }
          }
        }
      }
      &__item-image {
        width: 80px;
        height: 54px;
      }
    }
  }
}
