.users {
  --bg-user-container: #17121b;
  --user-container-ratio: 3.5rem;
  --plus-container-ratio: 1.25rem;

  margin: 0 calc(-1 * (var(--box-item-gap)/ 1.5)) 0 0;
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(var(--user-container-ratio), 1fr));
  display: flex;
  align-items: center;
  grid-gap: 1.5rem;

  &-item {
    width: var(--user-container-ratio);
    height: var(--user-container-ratio);

    flex: 0 0 auto;

    border-radius: var(--root-corner-radius);

    background-color: var(--bg-user-container);

    padding: 6px;

    position: relative;

    &__container {
      background-color: rgba(var(--clr-dark-8), 1);

      border-radius: var(--root-corner-radius);

      position: absolute;

      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);

      width: calc(100% - 12px);
      height: calc(100% - 12px);
    }

    &__add-box {
      width: var(--plus-container-ratio);
      height: var(--plus-container-ratio);

      position: absolute;

      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);

      &_bar {
        position: absolute;

        background-color: rgba(var(--clr-r), 1);
        width: var(--bar-w);
        height: var(--bar-h);

        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%) rotate(var(--bar-rotate));

        display: block;

        &:nth-child(1) {
          --bar-rotate: 90deg;
          --bar-w: 100%;
          --bar-h: 4px;
        }
        &:nth-child(2) {
          --bar-rotate: -90deg;
          --bar-w: 4px;
          --bar-h: 100%;
        }
      }
    }

    &__user-icon {
      aspect-ratio: 1 / 1;

      position: relative;
      z-index: 1;

      img {
        display: block;
        width: 100%;
        height: 100%;

        border-radius: var(--root-corner-radius);
      }
    }
  }
}
