.battle {
  --box-p: clamp(20px, 1.625vw, 26px);
  --item-h: 102px;
  --order-w: 58px;
  --case-image-ratio: clamp(60px, 5vw, 80px);
  --bg-prize: #1e1823;

  &-highlighted {
    color: rgba(var(--clr-r), 1);
    display: block;

    font-size: clamp(var(--t-md), 1.375vw, 22px);
    font-weight: 800;

    margin-top: 6px;
  }
  &-header {
    background-color: #16121b;

    padding: 0 34px;

    min-height: 94px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 40px;

    @include tablet {
      flex-direction: column;

      padding: 1.5rem 1rem;
      grid-gap: 1.25rem;

      .battle-header__col--flex {
        width: 100%;

        &:nth-child(2) {
          flex-direction: column;
          width: 100%;
        }
      }
      .battle-header__create {
        width: 100%;
      }
    }

    &__notif {
      color: rgba(var(--clr-w), 1);
      font-size: 1rem;
      font-weight: 600;


    }
    &__link {
      color: rgba(var(--clr-r), 1);

      @include hover {
        text-decoration: underline;
      }
    }
    &__create {
      min-width: 256px;
      min-height: 68px;

      filter: drop-shadow(0 0 1rem rgba(var(--clr-r), .5));

      transition: all 400ms ease;

      @include dfc;
      text-align: center;

      background-size: 100% 100%;
      background-image: url('../img/global/shape-button-overlay2x.webp');
      background-repeat: no-repeat;

      @include hover {
        filter: drop-shadow(0 0 1rem rgba(var(--clr-r), .75));
      }

      span {
        color: rgba(var(--clr-w), 1);
        text-transform: uppercase;
        font-size: 19px;
        font-weight: 800;
      }
    }
    &__col {
      &--flex {
        display: flex;
        align-items: center;
        grid-gap: 26px;
      }
    }
    &__winloose {
      color: rgba(var(--clr-w), 1);
      font-size: var(--t-base);
      font-weight: 600;
    }
  }
  &-box {
    background-color: rgba(var(--clr-dark-7), .61);
    border-radius: 0.5rem;
    padding: var(--box-p);

    &__item {
      display: grid;
      grid-template-columns: var(--order-w) minmax(200px, 300px) minmax(86px, 110px) minmax(400px, 1fr) minmax(180px, 210px);
      grid-gap: var(--box-item-gap);
      min-height: var(--item-h);
      justify-content: center;
      align-items: center;

      background-color: rgba(var(--bg-body), 1);
      padding: 0 clamp(14px, 1.5rem, 24px);

      border-radius: .5rem;

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &-order {
        --shape: polygon(0 0, 0% 100%, 100% 100%);
        --current-clr: 255, 255, 255;

        @include dfc;
        width: 3.625rem;
        height: 3.625rem;

        background-color: #17121b;

        border-radius: var(--root-corner-radius);

        border: 1px solid rgba(var(--current-clr), 1);
        box-shadow: 0 -6px 13px rgba(var(--current-clr), .88);

        position: relative;

        overflow: hidden;

        text-transform: uppercase;
        font-size: var(--t-xxl);
        font-weight: 700;
        color: rgba(var(--clr-w), 1);

        &::before,
        &::after {
          @include pseudo;

          background-color: rgba(var(--current-clr), 1);

          clip-path: var(--shape);

          width: 1.75rem;
          height: 1.125rem;

          filter: blur(2px);
        }

        &::before {
          bottom: -1px;
          left: -1px;
        }
        &::after {
          --shape: polygon(0 0, 100% 0, 100% 100%);

          top: -1px;
          right: -1px;
        }

        &--yellow {
          --current-clr: 249, 193, 73;
        }
        &--blue {
          --current-clr: 73, 160, 249;
        }
        &--red {
          --current-clr: 249, 78, 73;
        }
      }
    }
    &__actions {
      display: grid;
      grid-template-columns: 1fr 0.5fr;
      grid-gap: 1.5rem;
    }
    &__enter {
      background-color: transparent;
      border: 2px solid rgba(var(--clr-r), 1);

      border-radius: var(--root-corner-radius);

      padding: 1rem 1.125rem;

      transition: all 400ms ease;

      @include hover {
        background-color: rgba(var(--clr-r), 1);
      }

      & > span {
        font-size: var(--t-md);
        font-weight: 800;

        color: rgba(var(--clr-w), 1);
      }
    }
    &__track {
      background-color: rgba(var(--clr-r),1);
      border-radius: var(--root-corner-radius);

      transition: all 400ms ease;

      @include hover {
        background-color: rgba(var(--clr-r), .75);
      }

      img, svg {
        width: 1.5rem;
      }
    }
    &__prize {
      background-color: var(--bg-prize);
      border-radius: 6px;

      padding: 1rem 1.25rem;

      & > span {
        color: rgba(var(--clr-r), 1);
        font-size: var(--t-lg);
        font-weight: 800;
        line-height: 100%;

        white-space: nowrap;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &-cases {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
    grid-gap: 1.75rem;

    &__item {
      position: relative;
    }

    &__price {
      position: absolute;

      bottom: 6px;
      left: 50%;
      transform: translateX(-50%);

      @include hide-text(1);

      font-size: 13px;
      font-weight: 700;

      color: rgba(var(--clr-w), 1);
    }

    &__image-container {
      width: var(--case-image-ratio);
      height: var(--case-image-ratio);

      margin: 0 auto;

      position: relative;

      &::after {
        @include pseudo;
        pointer-events: none;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background-image: linear-gradient(to top, rgba(var(--bg-body), 1) 20%, transparent 60%);
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@include laptop {
  .battle {
    --box-item-gap: 1.5rem;

    &-cases {
      grid-area: cases;
    }
    &-box {
      &__item {
        padding: 1.25rem 1rem;
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
        'order prize actions actions'
        'users users cases cases';

        &-order {
          grid-area: order;
        }
      }
      &__prize {
        grid-area: prize;
      }
      &__users {
        grid-area: users;
      }
      &__actions {
        grid-area: actions;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: .5rem;
      }
    }
  }
}

@include small-tablet {
  .battle {
    &-box {
      &__item {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
        'order order'
        'prize prize'
        'cases cases'
        'users users'
        'actions actions';

        &-order {
          margin: 0 auto;
        }
      }
    }
  }
}
