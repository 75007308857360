.cases {
  --item-link-h: 70px;
  --cases-items-gap-x: 32px;
  --cases-items-gap-y: 30px;
  --case-item-value: 6;
  --case-image-container-h: clamp(180px, 16.75vw, 268px);

  margin-bottom: clamp(60px, 7.5vw, 120px);

  &__box {
    &:not(:last-child) {
      margin-bottom: clamp(54px, 5.75vw, 92px);
    }
  }
  &__head {
    text-align: center;

    margin-bottom: clamp(40px, 4vw, 64px);

    font-weight: 800;
    font-size: clamp(var(--t-xxl-2), 1.75vw, 28px);
    text-transform: uppercase;

    display: flex;
    flex-wrap: wrap;
    grid-gap: 8px;
    justify-content: center;

    &-text {
      top: -2px;
      position: relative;

      color: rgba(var(--clr-r-2), 1);

      @include zip-text;
    }
    &-highlighted {
      color: rgba(var(--clr-r), 1);
    }
  }
  &-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: var(--cases-items-gap-y) var(--cases-items-gap-x);
    &__image-container {
      min-height: var(--case-image-container-h);

      display: flex;
      align-items: flex-end;

      margin-bottom: 6px;

      transition: transform 400ms ease;

      img {
        display: block;
        height: 100%;
      }
    }
    &__item {
      width: calc((100% / var(--case-item-value)) - var(--cases-items-gap-x));

      display: flex;
      flex-direction: column;
      align-items: center;

      @include hover {
        .cases-grid__image-container {
          transform: translateY(-1rem);
        }
        .cases-grid__item-name {
          color: rgba(var(--clr-r), 1);
        }
      }

      &-content {
        text-align: center;
        display: flex;
        flex-direction: column;

        flex: 1;
      }

      &-name {
        flex: 1;
        font-size: clamp(var(--t-base), 1.125vw, var(--t-md));
        font-weight: 700;

        margin-bottom: 20px;

        transition: color 400ms ease;
      }
      &-link {
        min-height: var(--item-link-h);
        background-image: url('../img/cases/case-link-overlay.webp');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;

        @include dfc;

        position: relative;

        min-width: 200px;

        @include mobile {
          min-width: 160px;
          min-height: 52px;
        }

        @include hover {
          .cases-grid__link-price {
            transform: translateY(-1rem);
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
          }
          .cases-grid__link-open {
            opacity: 1;
            transform: translateY(0);
            visibility: visible;
            pointer-events: all;
          }
        }

        // &::before {
        //   @include pseudo;

        //   width: 100%;
        //   height: 40%;

        //   top: 50%;
        //   left: 50%;
        //   transform: translate(-50%, -50%);

        //   background-color: rgba(var(--clr-r), .2);

        //   filter: blur(20px);
        // }
      }
    }
    &__link {
      &-price,
      &-open {
        position: absolute;
        z-index: 1;
        transition: all 400ms ease;

        font-weight: 700;
        font-size: clamp(var(--t-base), 1.125vw, var(--t-md));
      }
      &-price {
        display: block;
        @include hide-text(1);

        color: rgba(var(--clr-r), 1);
      }
      &-open {
        transform: translateY(1rem);
        opacity: 0;
        pointer-events: none;
        visibility: hidden;

        color: rgba(var(--clr-dark-6), 1);
        text-transform: uppercase;
      }
    }
  }

}

@include desktop {
  .cases {
    --case-item-value: 5;
  }
}

@include laptop {
  .cases {
    --case-item-value: 4;
  }
}

@include tablet {
  .cases {
    --case-item-value: 3;
  }
}

@include small-tablet {
  .cases {
    --cases-items-gap-x: 0;
    --case-item-value: 2;

    &-grid {
      &__item {
        width: calc(100% / 2);
      }
    }
  }
}
