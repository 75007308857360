html {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  position: relative;
  overflow-x: inherit;
  width: 100%;
  min-height: -webkit-fill-available;
  margin: 0;

  display: flex;
  flex-direction: column;

  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  font-family: var(--ff);
  color: rgba(var(--clr-w),1.0);

  background: rgba(var(--bg-body), 1);

  -webkit-text-size-adjust: 100%;

  background-image: url('../img/global/bg.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom;

  @include laptop {
    background-size: 1200px auto;
    background-position: top left;
  }

  @include laptop {
    padding-bottom: 60px;
  }
  &::before {
    @include pseudo;

    width: 100%;
    height: 100%;

    z-index: 3;

    background-color: rgba(var(--clr-b), .5);

    opacity: 0;

    top: 0;
    left: 0;

    transition: all 300ms ease;

    pointer-events: none;
    visibility: hidden;
  }
}

.body--active {
  &::before {
    opacity: 1;

    visibility: visible;

    pointer-events: all;
  }
}

main {
  min-width: 0 !important;
  flex: 1;

  display: flex;
  flex-direction: column;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


a {
  display: inline-flex;
  margin: 0;

  font-style: inherit;
  font-weight: inherit;
  font-family: inherit;
  color: inherit;
  text-decoration: none;

  cursor: pointer;

  svg {
    pointer-events: none;
  }
}

button {
  display: inline-flex;
  margin: 0;
  padding: 0;

  line-height: initial;
  color: inherit;

  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  font-family: var(--ff);

  svg,img {
    pointer-events: none;
  }
}

ul,
ol,
li {
  margin: 0;
  padding: 0;

  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;

  object-fit: cover;
}

// picture {
//   display: flex;
// }

label {
  display: inline-flex;
}

input {
  width: 100%;

  background-color: none;
  border: none;
  outline: none;

  &::placeholder {
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;

  appearance: none;
}

textarea {
  border: none;
  outline: none;

  resize: none;
}

input[type="number"] {
  appearance: textfield;
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  white-space: nowrap;

  border: 0;

  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.container {
  position: relative;
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
  padding: 0 var(--container-offset);

  &--mode {
    max-width: var(--container-width-mode);
  }
  @include mobile {
    --container-offset: 12px;
  }
}

// .panel,
// [data-content] {
//   z-index: 20;

//   max-height: 0;
//   overflow: hidden;

//   transition: max-height 0.2s;
// }

// .dis-scroll {
//   position: fixed;
//   top: 0;
//   left: 0;

//   width: 100%;
//   // height: 100vh;
//   overflow: hidden;
//   // z-index: 9999;
//   background-attachment: fixed;
//   overscroll-behavior: none !important;
//   scroll-behavior: none !important;
// }

// .a-start {
//   align-items: flex-start !important;
//   padding-top: 0 !important;
//   height: calc(100% - var(--header-height)) !important;
//   top: calc(var(--header-height)) !important;
//   transition: none !important;
// }

.page--ios .dis-scroll {
  position: relative;
}

.overlay {
  @include overlay;
}

// * {
//   &::-webkit-scrollbar {
//     width: 7px;
//     height: 7px;
//     border-radius: 5px;
//     // display: none;

//     cursor: pointer;

//     @include small-tablet {
//       display: none;
//     }
//   }

//   &::-webkit-scrollbar-thumb {
//     background-color: var(--scrollbar-thumb);
//     cursor: pointer;

//     transition: all 0.2s;

//     // &:hover {
//     //   background-color: var(--scrollbar-thumb-hover);
//     // }
//   }

//   &::-webkit-scrollbar-track {
//     background-color: var(--scrollbar-track);

//     // &:hover {
//     //   background-color: var(--scrollbar-track-hover);
//     // }
//   }

//   &::-webkit-scrollbar-button {
//     display: none;

//     background-color: transparent;
//     box-shadow: none;
//   }
// }

fieldset {
  margin: 0;
  padding: 0;

  border: none;
}

.button {
  @include dfc;
  text-align: center;
}

.section-header {
  font-size: clamp(var(--t-xl), 1.75vw, var(--t-xxl-2));
  text-align: center;

  color: rgba(var(--clr-w), 1);

  margin-bottom: 44px;
}
















