.stat {
  --stat-grid-cols: 5;
  --bg-item-red: #{HexToRGB(#722029)};
  --bg-stat-item: #{HexToRGB(#201722)};
  --stat-grid-gap: clamp(32px, 5.875vw, 94px);

  margin: auto 0 -14px 0;

  position: relative;

  z-index: 3;

  &-container {
    max-width: calc(1612px + var(--container-offset) * 2);
  }
  &__flex {
    display: grid;
    grid-template-columns: repeat(var(--stat-grid-cols), 1fr);
    grid-gap: var(--stat-grid-gap);
  }
  &__card {
    min-height: 210px;

    background-image: linear-gradient(to bottom, rgba(var(--bg-item-red), .3), rgba(var(--bg-stat-item), .5) 80%);

    border-radius: 4px;

    padding: 2rem 2.5rem;

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    grid-gap: 30px;

    box-shadow: 0 0 0 -1rem rgba(var(--clr-dark-2), .05);

    position: relative;

    &::after {
      @include pseudo;
      width: 85%;
      height: 12px;
      bottom: -12px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #19131c;
      opacity: .75;
      border-radius: 0 0 4px 4px;
    }

    &-icon {
      width: 60px;
      height: 60px;

      svg, img {
        width: inherit;
        height: inherit;
        display: block;

        fill: rgba(var(--clr-r), 1);
      }
    }
    &-content {
      position: relative;

      display: flex;
      flex-direction: column;
      grid-gap: .5rem;
    }
    &-value {
      color: rgba(var(--clr-w), 1);
      font-size: clamp(var(--t-xxl), 2.25vw, var(--t-xxl-4));
      font-weight: 800;
    }
    &-subinfo {
      color: rgba(var(--clr-dark-5), 1);
      font-weight: 500;
      font-size: clamp(var(--t-sm), 1vw, var(--t-base));
    }
  }
}

@include desktop {
  .stat {
    --stat-grid-cols: 4;
    --stat-grid-gap: 1.5rem;
  }
}

@include tablet {
  .stat {
    --stat-grid-cols: 2;
    --stat-grid-gap: 1rem;

    &__card {
      padding: 1.25rem;
    }
  }
}

@include ios {
  .stat {
    &__flex {
      display: flex;
      flex-direction: column;
    }
  }
}
