.search-bar {
  --root-py: 12px;
  --root-px:  20px;
  --search-icon-ratio: 1.125rem;

  position: relative;

  &__icon {
    position: absolute;

    top: 50%;
    right: 14px;
    transform: translateY(-50%);

    svg {
      fill: rgba(var(--clr-r), 1);

      width: var(--search-icon-ratio);
      height: var(--search-icon-ratio);

      display: block;
    }
  }
  &__input {
    background-color: rgba(var(--bg-body), 1);

    border-radius: 4px;

    padding: var(--root-py) calc(var(--root-px) + var(--search-icon-ratio) + 0px + 14px) var(--root-py) var(--root-px);

    border: 1px solid #211b27;

    @include dfc;

    input {
      background-color: transparent;

      color: rgba(var(--clr-w), 1);

      font-size: 13px;
      font-weight: 500;
      line-height: 80%;

      padding: 0;

      font-family: var(--ff);

      &::placeholder {
        color: #68616f;
      }
    }
  }
}
