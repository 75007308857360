/* stylelint-disable */
@mixin dfc {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin pseudo {
  content: "";
  position: absolute;
}

@mixin image-set($pathToImage) {
  background-image: url("#{$pathToImage}.png");
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  background-image: image-set(
  /* stylelint-disable-next-line function-comma-newline-after */
  /* "#{$pathToImage}.avif"type("image/avif") */
  "#{$pathToImage}.webp"type("image/webp"),
  "#{$pathToImage}.png"type("image/png")
  );
}

@mixin footerToBottom {
  display: grid;
  min-height: 100vh;

  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
}

@mixin mr($value) {
  &:not(:last-child) {
    margin-right: $value;
  }
}

@mixin ml($value) {
  &:not(:last-child) {
    margin-left: $value;
  }
}

@mixin mb($value) {
  &:not(:last-child) {
    margin-bottom: $value;
  }
}

@mixin mt($value) {
  &:not(:last-child) {
    margin-top: $value;
  }
}

@mixin gradient-border($background, $border-width) {
  position: relative;
  &::before {
    pointer-events: none;
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: #{$border-width};
    background: #{$background};
    -webkit-mask:
       linear-gradient(#fff 0 0) content-box,
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}

@mixin flex-col($value) {
  width: calc(100% / $value);
}

// @mixin fix-offset($offset-value-top, $offset-value-bottom,  $offset-value){
//   padding: $offset-value-top $offset-value $offset-value-bottom;
//   margin: $offset-value-top  calc($offset-value * -1) $offset-value-bottom;
//   width: #{100 + $offset-value};
// }

$test: 16px;

@function rem($pixels) {
  @return #{$pixels /$test}rem;
}

@mixin zip-text {
  color: rgba(var(--clr-r), 1);
  position: relative;
  &::before {
    content: attr(data-text);
    color: rgba(var(--clr-w),1);
    text-shadow: 2px 1px 0px rgba(var(--clr-zip-text), .7);
    position: absolute;
    bottom: -2px;
    right: -2px;
    font-size: inherit;
    font-weight: inherit;
    text-transform: uppercase;
    letter-spacing: inherit;
  }
}
