.roulette {
  --bg-roulette-item: #{HexToRGB(#151622)};
  --roulette-image-h: 52px;
  --case-image-w: 100px;
  --root-link-corner-radius: 4px;
  $root-padd: 0 18px 14px 18px;
  $image-transition: transform .2s ease,opacity .2s ease;

  padding: 0.375rem 0 0.375rem 0.375rem;

  &-box {
    display: flex;
    align-items: stretch;
    grid-gap: 6px;

    &__image {
      height: var(--roulette-image-h);

      position: absolute;

      top: 24px;
      left: 50%;
      transform: translateX(-50%);

      transition: $image-transition;

      img {
        object-fit: contain;

        height: inherit;
        width: inherit;
      }
    }
    &__content {
      width: 100%;
      height: 100%;

      display: flex;
      align-items: flex-end;

      padding: $root-padd;

      &-name {
        transition: opacity .2s ease;

        pointer-events: none;

        text-align: left;

        width: 100%;

        color: #fae3e5;
        font-size: var(--t-sm);

        @include hide-text(1);
      }
    }
    &__link {
      border-radius: var(--root-link-corner-radius);

      width: 158px;
      height: 112px;
      position: relative;

      background-image: linear-gradient(to bottom, rgba(var(--bg-item), .35), rgba(var(--bg-roulette-item), .25) 100%);

      border-top: 2px solid rgba(var(--bg-item), 1);

      position: relative;

      &:hover {
        .roulette-box__case-image {
          transform: translateX(-50%) scale(1);

          opacity: 1;
        }
        .roulette-box__content-name {
          opacity: 0;
        }
        .roulette-box__case-name {
          opacity: 1;
        }
        .roulette-box__image {
          opacity: 0;
          transform: translateX(-50%) scale(.7);
        }
      }

      &::before {
        @include pseudo;

        width: 36px;
        height: 82px;

        background-image: url('../img/roulette-items/roulette-item-fire-overlay.webp');
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: bottom;

        bottom: 0;
        right: 0;

        opacity: .7;

        pointer-events: none;
      }

      &--red {
        --bg-item: var(--bg-item-red);
      }
      &--blue {
        --bg-item: #{HexToRGB(#1d44d6)};
      }
      &--ppl {
        --bg-item: #{HexToRGB(#2e1247)};

        background-image: linear-gradient(rgba(var(--bg-item), 1), rgba(var(--bg-roulette-item), .25));

        border-top: 2px solid #6b12ce;
      }
      &--grey {
        --bg-item: var(--clr-w);

        background-image: linear-gradient(to bottom, rgba(var(--bg-item), .125), rgba(var(--bg-roulette-item), 1));

        border-top: 2px solid #5b5d70;
      }
    }
    &__case-image {
      height: 82px;
      position: absolute;

      top: 4px;
      left: 50%;
      transform: translateX(-50%) scale(.7);

      opacity: 0;

      transition: $image-transition;

      img {
        display: block;
        width: 100%;
        height: 100%;

        object-fit: contain;
      }
    }
    &__case-name {
      opacity: 0;

      transition: opacity .2s ease;

      pointer-events: none;

      text-align: left;

      width: 100%;

      color: #fae3e5;
      font-size: var(--t-sm);

      @include hide-text(1);
    }
    &__hidden {
      width: 100%;
      height: 100%;
      position: absolute;

      padding: $root-padd;

      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
  }
}

@include small-tablet {
  .roulette {
    &-box {
      &__case-image {
        top: -10px;
      }
      &__image {
        top: 4px;
      }
      &__hidden {
        padding: 10px;
      }
      &__content {
        padding: 10px;
      }
      &__content-name {
        font-size: var(--t-xxs);
      }
      &__case-name {
        font-size: var(--t-xxs);
      }
      &__link {
        width: 110px;
        height: 74px;

        &::before {
          width: 26px;
          height: 54px;
        }
      }
    }
  }
}
