.upgrade {
  margin-bottom: 60px;

  &-header {
    background-color: #16121b;

    overflow: hidden;

    min-height: var(--upgrade-tabs-h);

    display: flex;
    align-items: center;
    justify-content: space-between;

    max-width: 1600px;

    padding: 0 clamp(20px, 2.25vw, 36px);

    border-radius: 5px;

    margin: 0 auto clamp(20px, 2.5vw, 40px) auto;

    @include tablet {
      flex-direction: column-reverse;

      padding: 1rem;

      min-height: calc(var(--upgrade-tabs-h) * 1.5);
    }
  }
  &-tabs {
    display: flex;
    align-items: center;

    &__item {
      padding: 0 clamp(1rem, 2vw, 2rem);
      font-weight: 800;
      text-transform: uppercase;
      font-size: var(--t-base);
      color: rgba(var(--clr-w), 1);

      position: relative;

      cursor: pointer;

      &::after {
        @include pseudo;

        width: 100%;
        height: 40px;

        filter: blur(6px);

        background-color: rgba(var(--clr-r), .05);

        left: 50%;
        transform: translateX(-50%);

        bottom: calc(-1 * (var(--upgrade-tabs-h) / 2) + 10px);

        opacity: 0;

        transition: all 300ms ease 100ms;

        pointer-events: none;
      }
      &::before {
        @include pseudo;

        width: 100%;
        height: 2px;

        left: 50%;
        transform: translateX(-50%);

        background-color: rgba(var(--clr-r), 1);

        bottom: calc(-1 * (var(--upgrade-tabs-h) / 2) + 10px);

        pointer-events: none;

        transition: all 300ms ease;

        opacity: 0;
      }
      @include tablet {
        &::before {
          bottom: -16px;
        }
        &::after {
          bottom: -16px;
        }
      }
      @include hover {
        color: rgba(var(--clr-r), 1);
      }
      &--selected {
        color: rgba(var(--clr-r), 1);

        &::before {
          opacity: 1;
        }
        &::after {
          opacity: 1;
        }
      }
    }
  }
  &-help {
    text-align: right;

    color: rgba(var(--clr-dark-3), 1);
    font-size: var(--t-base);
    font-weight: 500;
    line-height: 140%;

    &__link {
      color: rgba(var(--clr-r), 1);
    }
    @include tablet {
      text-align: center;
    }
  }
  &-inventory {
    width: 100%;
    height: 100%;

    position: relative;

    &::before {
      @include pseudo;

      background-image: linear-gradient(to top, rgba(var(--bg-inventory-scroll-container), .7), transparent);

      width: calc(100% + (var(--block-p) * 2));
      height: calc(100%  + (var(--block-p)));

      top: 0;
      left: 50%;
      transform: translateX(-50%);

      pointer-events: none;

      z-index: 2;
    }
  }
  &-flex {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: clamp(20px, 2.75vw, 44px);

    max-width: 1600px;

    margin: 0 auto;

    &__block {
      background-color: #16121b;

      border-radius: 6px;

      padding: var(--block-p);

      overflow: hidden;
    }
  }
  &-block {
    &__header {
      margin-bottom: clamp(50px, 4vw, 64px);

      position: relative;

      min-height: 42px;

      display: flex;
      align-items: center;

      &--flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &::after {
        @include pseudo;

        width: calc(100% + (var(--block-p) * 2));
        height: 3px;

        bottom: calc(-1 * (42px / 2));
        left: 50%;
        transform: translateX(-50%);

        background-color: rgba(var(--bg-body), 1);
      }
    }
    &__head {
      display: flex;
      align-items: center;
      grid-gap: 1rem;

      &-text {
        color: rgba(var(--clr-w), 1);

        font-size: clamp(var(--t-base), 1.125vw, var(--t-md));
      }

      &-icon {
        svg,
        img {
          fill: rgba(var(--clr-r), 1);

          width: 1.5rem;
          height: 1.5rem;

          display: block;
        }
      }
    }
  }
  &-actions {
    display: grid;
    place-items: center;

    &__go {
      background-image: url('../img/global/shape-button-overlay2x.webp');
      background-size: 100% 100%;

      width: 300px;
      min-height: 74px;

      text-align: center;

      @include dfc;

      &-text {
        font-size: 19px;
        text-transform: uppercase;
        text-shadow: 0 2px 0 rgba(87, 29, 37, 0.33);
        font-weight: 800;
      }
    }
  }
  &-layout {
    margin-bottom: clamp(54px, 4.5vw, 72px);

    &__box {
      &-overlay {
        transition: all 400ms ease;
        img {
          display: block;
        }
      }
    }
    &__list {
      display: none;
    }
    &__sub {
      position: absolute;
      bottom: 0;
      left: 0;

      width: 100%;

      text-align: center;

      color: #675e6d;
      font-size: var(--t-base);
      font-weight: 600;
    }
    &__grid {
      display: grid;
      grid-template-columns: repeat(var(--upgrade-grid-cols), 1fr);
      grid-gap: 10px;
      align-items: center;

      max-width: 1248px;
      margin: 0 auto 30px auto;

      position: relative;

      // &::before {
      //   @include pseudo;

      //   width: 70%;
      //   height: 50%;

      //   background-image: url('../img/upgrade/upgrade-branches2x.webp');
      //   background-size: 100% 100%;
      //   background-repeat: no-repeat;

      //   top: 20px;
      //   left: 50%;
      //   transform: translateX(-50%);
      // }

      &-item {
        grid-column: span 2;

        height: 100%;

        @include dfc;
        flex-direction: column;

        position: relative;

        cursor: pointer;

        @include hover {
          .upgrade-layout__box-overlay {
            filter: drop-shadow(0 0 .25rem rgba(var(--clr-r), 1));

            transform: scale(1.015);
          }
        }

        &--main {
          grid-column: span 3;

          transform: translateY(-14px);

          .upgrade-layout__box-overlay {
            max-width: 370px;
            margin: 0 auto;
          }
        }
      }
    }
  }
  &-info {
    grid-column: span 1;

    display: flex;
    flex-direction: column;
    justify-content: center;

    font-size: clamp(var(--t-sm), 1vw, var(--t-base));
    font-weight: 700;
    line-height: 150%;

    &__value {
      color: rgba(var(--clr-w), 1);
    }
    &__sub {
      color: rgba(var(--clr-dark-3), 1);
    }

    &--left {
      align-items: flex-start;
    }
    &--right {
      align-items: flex-end;
    }
  }
}

@include small-tablet {
  .upgrade {
    &-flex {
      display: flex;
      flex-direction: column;
    }
  }
}

@include mobile {
  .upgrade {
    .search-bar {
      width: 100%;
    }
    &-block {
      &__header {
        &--flex {
          flex-direction: column;
          grid-gap: 1rem;
        }
      }
    }
  }
}

@include small-tablet {
  .upgrade {
    --upgrade-grid-cols: 5;
    &-layout {
      max-width: 400px;
      margin: 0 auto 50px auto;
      &__sub {
        font-size: var(--t-sm);

        bottom: -10px;
      }
      &__grid {
        grid-template-areas:
        'info-1 info-1 ... info-2 info-2'
        'med-1 med-1 ... med-2 med-2'
        '... main main main ...';
        grid-gap: 1.5rem 0;
        &-item {
          &:first-child {
            grid-area: med-1;
          }
          &:last-child {
            grid-area: med-2;
          }
          &--main {
            grid-area: main;
          }
        }
      }
    }
    &-info {
      justify-content: center;
      text-align: center;
      & > * {
        width: 100%;
      }
      &--right {
        grid-area: info-1;
      }
      &--left {
        grid-area: info-2;
      }
    }
  }
}

@include mobile {
  .upgrade {
    &-layout {
      &__sub {
        font-size: 10px;
      }
      &__grid {

      }
    }
  }
}

