.fav-case {
  --bg-index: #{HexToRGB(#221c2a)};

  padding: 0.375rem 1.25rem 1.5rem 1.25rem;

  @include dfc;

  &__flex {
    display: grid;
    grid-template-columns: 55% 1fr;
    grid-gap: 2rem;
    align-items: center;

    width: 100%;
  }
  &__index {
    position: absolute;

    background-color: rgba(var(--bg-index), .8);

    border-radius: .25rem;

    padding: 10px 1rem;

    text-align: left;

    top: 1rem;
    left: 4px;

    &-value {
      color: rgba(var(--clr-r), 1);
      font-size: var(--t-base);
      font-weight: 800;
    }
    &-sub {
      color: rgba(var(--clr-w), 1);
      font-weight: 700;
      font-size: var(--t-sm);
    }
  }
  &__data {
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;
  }
  &__header {
    font-size: var(--t-base);
    font-weight: 600;
    color: rgba(var(--clr-w), 1);

    margin-bottom: .25rem;

    @include hide-text(2);
  }
  &__case-name {
    color: rgba(var(--clr-dark-3), 1);
    font-size: var(--t-sm);
    font-weight: 600;

    @include hide-text(2);
  }
  &__open {
    background-color: rgba(var(--clr-r), 1);

    border-radius: 5px;

    @include dfc;
    text-align: center;

    padding: 1rem 22px;

    width: fit-content;

    &-text {
      font-size: var(--t-base);
      font-weight: 800;
      color: rgba(var(--clr-w), 1);
    }
  }
  &__item {
    &-image {
      max-width: 248px;
      width: 100%;
      position: relative;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@include small-tablet {
  .fav-case {
    padding: 1.5rem 1rem;

    &__data {
      text-align: center;

      width: 100%;
    }
    &__item {
      width: 100%;

      &-image {
        margin: 0 auto;
      }
    }
    &__open {
      width: 100%;
      max-width: 240px;
      margin: 0 auto;
    }
    &__flex {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
