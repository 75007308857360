@mixin switch {

  &--dark {
    .switch__slider {
      background-color: rgba(var(--bg-q),1.0);
    }
  }

  position: relative;
  display: inline-block;
  min-width: 46px;
  min-height: 24px;

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}


&__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(var(--bg-e),1.0);
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 100px;

  &:before {
      position: absolute;
      content: "";
      height: 21px;
      width: 21px;
      left: 1px;
      bottom: 1.5px;
      background-color: rgba(var(--bg-a),1.0);
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }
}

input:checked + .switch__slider {
  background-color: rgba(var(--bg-e),1.0);
}

input:checked + .switch__slider:before {
  transform: translateX(23px);
}
}

.switch{
  @include switch
}
