.bonus {
  --banner-h: 700px;
  --bonus-flex-gap: clamp(24px, 3.375vw, 54px);
  --bg-bonus-flex-item: #{HexToRGB(#201a25)};
  --bonus-flex-item-h: 19.25rem;
  --flex-item-image-w: 11.75rem;
  --bg-slider: #{HexToRGB(#1c141f)};

  position: relative;
  &__container {
    position: relative;

    &::before,
    &::after {
      @include pseudo;

      background-image: radial-gradient(rgba(var(--bg-body), 1) 0, transparent 90%);

      top: 50%;

      transform: translateY(-50%);

      width: 30%;
      height: 600px;

      pointer-events: none;

      z-index: 1;
    }
    &::before {
      left: -100px;
    }
    &::after {
      right: -100px;
      transform: translateY(-50%) scaleX(-1);
    }
  }
  &-slider {
    padding-top: clamp(40px, 5vw, 80px);
    background-color: rgba(var(--bg-slider), .8);
  }
  &__body {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    position: relative;

    &::before,
    &::after {
      @include pseudo;
      width: 100%;
      height: 3.75rem;

      background-image: linear-gradient(to bottom, #1c141f, transparent 100%);
    }
    &::before {
      top: 0;
      left: 0;
    }
    &::after {
      bottom: -50px;
      left: 0;

      height: 30%;

      transform: scaleY(-1);
    }
  }
  &__banner {
    min-height: var(--banner-h);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    position: relative;

    padding-top: 6rem;

    &-image {
      max-width: 22.125rem;

      margin-bottom: 10rem;

      img {
        display: block;
        width: 100%;
      }
    }
  }
  &__link {
    background-image: url('../img/global/shape-button-overlay2x.webp');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    min-height: 90px;
    max-width: 340px;
    width: 100%;

    transform: rotate(-5deg) skew(-10deg);

    position: absolute;
    bottom: 9rem;

    transition: transform 400ms ease;

    @include hover {
      transform: rotate(-5deg) skew(-10deg) scale(1.05);
    }

    & > span {
      font-size: var(--t-xl);
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 1px;

      transform: translateY(-.25rem);
    }
  }
  &-slider {
    &__flex {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
      grid-gap: 2.5rem var(--bonus-flex-gap) ;

      @include mobile {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2rem 4px;

        .bonus-slider__flex-image {
          max-width: 4.5rem;

          margin: -2rem auto 20px auto;
        }
        .bonus-slider__flex-item {
          min-height: 120px;
        }
        .bonus-slider__flex-info {
          font-size: var(--t-xs);

          @include hide-text(2);

          padding: 0 10px;
        }
      }

      &-item {
        min-height: var(--bonus-flex-item-h);

        border-radius: .5rem;

        background-image: linear-gradient(to bottom, rgba(var(--bg-bonus-flex-item), 1), transparent);

        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &-info {
        padding: 0 clamp(20px, 1.5vw, 24px);

        text-align: center;

        font-size: var(--t-md);
        font-weight: 600;
      }
      &-image {
        max-width: var(--flex-item-image-w);

        margin: -2rem auto 40px auto;

        img {
          filter: drop-shadow(0 0 1rem rgba(77, 187, 246, .25));

          width: 100%;
          display: block;
        }
      }
    }
  }
}

@include laptop {
  .bonus {
    --banner-h: 500px;

    &__banner {
      justify-content: flex-start;

      &-image {
        max-width: 17.5rem;

        margin: -2rem 0 0 0;
      }
    }
    &__link {
      bottom: 7rem;
    }
  }
}
