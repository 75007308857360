.modal {
  --bg-modal: #{HexToRGB(#1a151f)};

  background-color: rgba(var(--bg-modal), .8);

  position: fixed;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 10;

  transition: all 400ms ease;

  opacity: 0;

  visibility: hidden;

  pointer-events: none;

  &--active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;

    .modal-body {
      opacity: 1;
      transform: scale(1);
    }
  }

  &-container {
    padding: 1rem;

    height: 100%;

    @include dfc;

    @include tablet {
      align-items: flex-start;

      overflow-y: scroll;
    }
  }
  &-close {
    position: absolute;

    right: 1rem;
    top: 1rem;

    width: 48px;
    height: 48px;

    border-radius: 50%;

    transition: all 400ms ease;

    @include hover {
      background: rgba(var(--clr-w), .2);
    }

    span  {
      display: block;

      display: block;
      width: 24px;
      height: 0.25rem;
      border-radius: 1px;
      background-color: rgba(var(--clr-w), 1);

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%) rotate(var(--degree));
      transition: 200ms ease;

      &:nth-child(1) {
        --degree: 45deg;
      }
      &:nth-child(2) {
        --degree: -45deg;
      }
    }
  }
  &-header {
    text-align: center;

    margin-bottom: 100px;

    &__highlighted {
      color: rgba(var(--clr-r), 1);
      font-size: var(--t-md);
      font-weight: 700;
    }
    &__span {
      display: block;

      position: relative;
      @include zip-text();

      font-size: 28px;
      text-transform: uppercase;
      font-weight: 700;
      color: rgba(var(--clr-r), 1);

      margin-bottom: 10px;
    }
  }
  &-image {
    width: 176px;
    height: 120px;

    margin: 0 auto 76px auto;

    position: relative;

    z-index: 1;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  &-info {
    text-align: center;

    margin-bottom: 40px;
  }
  &-name {
    color: rgba(var(--clr-w), 1);
    font-weight: 600;
    font-size: var(--t-lg);
    margin-bottom: 6px;
  }
  &-type {
    color: rgba(var(--clr-r), 1);
    font-weight: 500;
    font-size: var(--t-sm);
  }
  &-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;

    &__sell,
    &__repeat {
      padding: 19px 26px;

      @include dfc;
      text-align: center;

      color: rgba(var(--clr-w), 1);
      font-size: var(--t-md);
      font-weight: 600;

      border-radius: 1.5rem;
    }
    &__repeat {
      background-image: url('../img/modal/modal-repeat-overlay.webp');
      background-size: 100% 100%;
      background-repeat: no-repeat;

      border: 1px solid rgba(var(--clr-r), .1);

      width: 100%;
    }
    &__sell {
      border: 2px solid #ef4b47;;
    }
  }
  &-body {
    max-width: 438px;
    width: 100%;

    padding: 50px 20px;

    transition: all 300ms ease 100ms;

    opacity: 0;

    transform: scale(.9);

    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: .5rem;

    background: #1e1823 url('../img/modal/modal-overlay.webp');
    background-size: 100% 100%;
    background-repeat: no-repeat;

    position: relative;

    &::after {
      @include pseudo;

      width: 130px;
      height: 240px;

      background-image: url('../img/modal/modal-fire-overlay@4x.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;

      top: 136px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@include tablet {
  .modal {
    &-header {
      margin-bottom: 50px;
    }
    &-info {
      margin-bottom: 20px;
    }
  }
}
