// --------------- hex to rgba mixin ---------------
@function HexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}
:root {
// --------------- default global variable ---------------
  --body-bg: #12192B;
  --footer-bg: #0C1323;
  --body-font-size: 16px;
  --content-width: 1772px;
  --content-width-mode: 1280px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-width-mode: calc(var(--content-width-mode) + (var(--container-offset) * 2));
  --box-item-gap: clamp(40px, 5vw, 80px);
  --root-corner-radius: 6px;
  --rating-user-icon-ratio: 44px;
  --clr-panel-button-text: #92777a;
  --panel-header-h: 90px;
  --panel-grid-gap: 22px;
  --root-item-py: 10px;
  --root-item-px: 12px;
  --root-box-py: clamp(28px, 2.625vw, 42px);
  --root-box-px: clamp(24px, 2.75vw, 44px);
  --item-image-h: 60px;
  --bg-overlay: #{HexToRGB(#17121b)};
  --item-border-w: 2px;
  --root-corner-radius: 6px;
  --root-options-button-py: 10px;
  --root-options-button-px: 14px;
  --panel-item-h: 128px;
  --bg-inventory-scroll-container: #{HexToRGB(#141018)};
  --upgrade-grid-cols: 9;
  --upgrade-tabs-h: 100px;
  --block-p: clamp(1rem, 2vw, 2rem);
  --bg-range: #{HexToRGB(#1c1723)};
// --------------- default JS variable ---------------
  --size: Min(24vw, 264px);
// --------------- default JS tablet variable ---------------
  // @media (max-width: 1024px) {
  //   --header-height: 65px;
  // }

// --------------- default JS small-tablet variable ---------------
  // @media (max-width: 767px) {
  //   --header-height:82px;
  // }


// ---------------- font - sizes --------------------------
--t-xxs: 10px;
--t-xs: 12px;
--t-sm: 14px;
--t-base: 16px;
--t-md: 18px;
--t-lg: 20px;
--t-xl: 24px;
--t-xxl: 26px;
--t-xxl-2: 28px;
--t-xxl-3: 32px;
--t-xxl-4: 36px;
// --------------- default scroll variable ---------------
  --scrollbar-thumb: #6866F6;
  --scrollbar-thumb-hover: #f6866F6ff;
  --scrollbar-track:  #05031d;
  --scrollbar-track-hover: #05031d;
  --overlay-bg: rgba(0, 0, 0, 0.5);

// --------------- default select variable ---------------
  --select-inner-bg: #404040;
  --select-inner-border: #FDFDFD;
  --select-focus-border: #FDFDFD;

// --------------- default font-family variable ---------------
  --ff: "Exo 2", sans-serif; // var(--font-family);
// --------------- default text color variable ---------------
  // --burger-cl: #{HexToRGB(#00d655)};

--clr-b: #{HexToRGB(#000000)};
--clr-w: #{HexToRGB(#ffffff)};
--clr-r: #{HexToRGB(#f94e49)};
--clr-grn: #{HexToRGB(#29ffa2)};
--clr-ppl: #{HexToRGB(#700ed8)};
--clr-r-2: #{HexToRGB(#c82039)};
--clr-p: #{HexToRGB(#d9077c)};
--clr-gr: #{HexToRGB(#5e6072)};
--clr-y: #{HexToRGB(#ffc600)};
--clr-bl: #{HexToRGB(#0690c1)};
--clr-bl-2: #{HexToRGB(#003cff)};
--clr-dark-1: #{HexToRGB(#1c1621)};
--clr-dark-2: #{HexToRGB(#9b2d30)};
--clr-dark-3: #{HexToRGB(#9c7f81)};
--clr-dark-4: #{HexToRGB( #36282d)};
--clr-dark-5: #{HexToRGB(#a58b94)};
--clr-dark-6: #{HexToRGB(#ffbe84)};
--clr-dark-7: #{HexToRGB(#141018)};
--clr-dark-8: #{HexToRGB(#1c1723)};
--clr-dark-9: #{HexToRGB(#231d28)};
--clr-10: #{HexToRGB(#bbc0ca)};
--clr-14: #{HexToRGB(#ecbe08)};
--clr-15: #{HexToRGB(#0B1122)};
--clr-zip-text: #{HexToRGB(#20ffff)};
--clr-rules-text: #{HexToRGB(#b4b0b7)};


// --------------- default background clr variable ---------------
--bg-body: #{HexToRGB(#1a151f)};
--bg-b: #{HexToRGB(#000000)};
--bg-item-red: #{HexToRGB(#d7087a)};
--bg-item-ppl: #{HexToRGB(#700fd7)};
--bg-rating-item: #{HexToRGB(#211b28)};
--bg-rules-box: #{HexToRGB(#1e1823)};

// --------------- default border cl variable ---------------
--br-a: #3D3E5C; // var(--br-a);
--br-b: #262D3D; // var(--br-b);
--br-c: #D72F33; // var(--br-c);
--br-d: #1E6FF6; // var(--br-d);
--br-e: #ECBF08; // var(--br-e);

--br-f: #546DB3; // var(--br-f);
--br-g: #66F6A8; // var(--br-g);


// --------------- gradient background cl variable ---------------

// ---------------default background modal variable-----------

}
