.profile {
  --profile-user-ratio: 62px;
  --grid-gap: 1.5rem;
  --root-box-py: 1.25rem;
  --root-box-px: 1.5rem;
  --bg-input: #{HexToRGB(#1b151f)};
  --bg-link-box: #{HexToRGB(#1f1a27)};
  --input-py: 1.125rem;
  --input-px: 1.25rem;
  --copy-btn-ratio: 38px;

  margin-bottom: clamp(44px, 5.25vw, 84px);
  &__container {
    max-width: calc(1692px + (var(--container-offset) * 2));
  }

  &-link {
    padding: var(--root-box-py) var(--root-box-px);

    background-color: rgba(var(--bg-link-box), 1);

    &__field {
      position: relative;

      background-color: rgba(var(--bg-input), 1);

      border-radius: .5rem;
    }
    &__input {
      padding: var(--input-py) calc(var(--input-px) + var(--copy-btn-ratio)) var(--input-py) var(--input-px);

      background-color: transparent;

      color: rgba(var(--clr-dark-3), 1);
      font-size: var(--t-sm);
      font-weight: 600;
    }
    &__copy {
      width: var(--copy-btn-ratio);
      height: var(--copy-btn-ratio);

      @include dfc;

      border-radius: 5px;

      background-color: rgba(var(--clr-r), 1);

      position: absolute;

      right: 4px;
      top: 50%;

      transform: translateY(-50%);

      img, svg {
        width: 1.125rem;
        height: 1.125rem;
      }
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 1.5rem;

      &-text {
        font-weight: 500;
        font-size: var(--t-base);
      }

      &-steam {
        color: rgba(var(--clr-r), 1);
        font-weight: 500;
        font-size: var(--t-sm);

        @include hover {
          text-decoration: underline;
        }
      }
    }
  }

  &-user {
    padding: var(--root-box-py) var(--root-box-px);
    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-gap: 1rem;
      flex-wrap: wrap;
    }
    &__options {
      display: flex;
      align-items: center;
      grid-gap: .5rem;
    }
    &__info {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 1.25rem;
      align-items: center;
    }
    &__data {
      display: flex;
      flex-direction: column;
      grid-gap: 8px;
    }
    &__name {
      font-size: var(--t-md);
      font-weight: 700;

      color: rgba(var(--clr-w), 1);

      max-width: 8.75rem;

      overflow: hidden;
      white-space: nowrap;
      text-overflow:clip;
    }
    &__balance {
      color: rgba(var(--clr-r), 1);
      font-size: var(--t-md);
      font-weight: 700;
    }
    &__icon {
      border-radius: .25rem;

      width: var(--profile-user-ratio);
      height: var(--profile-user-ratio);

      img {
        border-radius: inherit;
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: 36.8% 1fr 1fr;
    grid-gap: var(--grid-gap);

    &__box {
      background-color: rgba(var(--clr-dark-8), 1);

      &--wide {
        grid-column: 1 / -1;

        background-color: #17121b;

        border-radius: 4px;

        overflow: hidden;
      }
    }
  }
  &__balance-up {
    --root-btn-py: 12px;
    --root-btn-px: 14px;

    grid-gap: 10px;

    padding: var(--root-btn-py) var(--root-btn-px);

    border-radius: 6px;

    border: 1px solid rgba(var(--clr-r), 1);

    background-image: linear-gradient(to top, rgba(var(--clr-r), .3) 2%, rgba(var(--clr-b), 0.05));


    & > span {
      font-size: var(--t-base);
      font-weight: 700;
      line-height: 100%;

      color: rgba(var(--cl-w), 1);
    }
  }
}

@include laptop {
  .profile {
    &-grid {
      grid-template-columns: repeat(4, 1fr);
      &__box {
        grid-column: span 2;
        &--wider {
          grid-column: 1 / -1;
        }
        &--wide {
          grid-column: 1 / -1;
        }
      }
    }
  }
}

@include small-tablet {
  .profile {
    --root-box-px: 1rem;

    &__balance-up {
      width: 100%;
    }
    &-link {
      &__header {
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 1rem;
      }
    }
    &-user {
      &__row {
        flex-direction: column;
        grid-gap: 1.5rem;
      }
      &__options {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: .25rem;
      }
      &__info {
        width: 100%;
      }

    }
    &-grid {
      &__box {
        min-height: 240px;

        grid-column: 1 / -1;
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .profile {
    &-user {
      &__options {
        grid-gap: .5rem;
        display: flex;
        flex-direction: column;
      }
    }
  }
  .panel {
    &-history {
      width: 100%;
    }
  }
}
