.best-drop {
  @include dfc;

  padding: 0 1rem;

  position: relative;

  overflow: hidden;

  &:before {
    @include pseudo;

    width: 60%;
    height: 100%;

    background-color: rgba(var(--clr-r), .1);

    filter: blur(2rem);

    border-radius: 50%;

    left: 0;
  }
  &__flex {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: clamp(24px, 2.125vw, 34px);

    position: relative;
  }
  &__header {
    font-size: var(--t-base);
    font-weight: 700;
    color: rgba(var(--clr-w), 1);
    line-height: 140%;

    @include hide-text(2);
  }
  &__name {
    font-size: var(--t-sm);
    font-weight: 600;

    color: rgba(var(--clr-dark-3), 1);

    @include hide-text(2);
  }
  &__info {
    margin-bottom: 1.5rem;
  }
  &__price {
    display: flex;
    align-items: center;
    gap: 10px;

    line-height: 140%;

    &-header {
      font-size: 15px;
      font-weight: 600;

      color: rgba(var(--clr-w), 1);
    }

    &-value {
      color: rgba(var(--clr-r), 1);
      font-size: var(--t-md);
      font-weight: 700;
    }

    &-icon {
      background-color: rgba(var(--clr-dark-8), 1);
      border: 2px solid rgba(var(--clr-r), 1);

      border-radius: 50%;

      flex: 0 0 auto;

      width: 38px;
      height: 38px;

      @include dfc;

      position: relative;

      &::before {
        @include pseudo;

        width: 20px;
        height: 20px;

        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);

        background-color: rgba(var(--clr-r), .75);

        border-radius: 50%;

        filter: blur(8px);
      }

      svg {
        width: 14px;
        height: 18px;

        fill: rgba(var(--clr-r), 1);
      }
    }
  }
  &__image {

    max-width: 170px;
    width: 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

@include small-tablet {
  .best-drop {
    padding: 1.5rem 1rem;

    &__flex {
      width: 100%;

      display: flex;
      align-items: center;
      flex-direction: column;
    }
    &__data {
      width: 100%;
      text-align: center;
    }
    &__price {
      justify-content: center;
    }
    &__image {
      margin: 0 auto;
    }
  }
}
