.header {
  --logo-w: 164px;
  --header-h: 114px;
  --bg-steam: #{HexToRGB(#1a1723 )};
  $root-inner-px: 1.5rem;

  padding: clamp(14px, 1.625vw, 26px) 0 0 0;

  margin-bottom: clamp(30px, 3.125vw, 50px);

  overflow: hidden;

  position: relative;

  z-index: 7;

  &--no-spacing {
    margin-bottom: 0;
  }

  &-support {
    background-color: rgba(var(--bg-rating-item), 1);

    padding: 8px 12px;

    border-radius: 6px;

    @include dfc;
    grid-gap: 12px;
    text-align: center;

    &__icon {
      width: 1.25rem;
      height: 1.25rem;
    }
    &__text {
      font-size: 13px;
      font-weight: 800;
      text-transform: uppercase;
      color: rgba(var(--clr-w), 1);
    }
  }
  &-nav {
    &__list {
      display: flex;
      align-items: center;

      grid-gap: 12px;
    }
    &__link {
      padding: 8px 12px;

      color: rgba(var(--clr-dark-3), 1);

      font-weight: 600;

      font-size: var(--t-base);

      transition: color 400ms ease;

      @include hover {
        color: rgba(var(--clr-w), 1);
      }
    }
  }
  &-row {
    display: flex;
    align-items: center;
    grid-gap: clamp(1.5rem, 2vw, 2rem);
  }
  &-steam {
    padding: 20px 24px;

    @include dfc;
    grid-gap: 1rem;

    background-image: linear-gradient(to right, rgba(var(--bg-steam), 1), rgba(155, 45, 48, .05));

    text-align: center;

    position: relative;

    border-radius: 4px;

    overflow: hidden;

    border: 1px solid transparent;

    transition: all 400ms ease;

    &__shadow {
      position: absolute;

      bottom: -13px;
      right: 0;

      width: 100%;
      height: 20px;

      background-image: linear-gradient(to left, rgba(var(--clr-r), .85), transparent 50%);

      transition: all 400ms ease;

      filter: blur(4px);
    }

    @include hover {
      border: 1px solid rgba(var(--clr-r), .25);
      .header-steam__shadow {
        height: 20px;
        bottom: -5px;
      }
    }

    &::before {
      @include pseudo;

      bottom: 0;
      left: 0;

      height: 1px;
      width: 100%;

      background-image: linear-gradient(to left, rgba(var(--clr-r), 1), transparent 70%);
    }
    &::after {
      @include pseudo;

      width: 70%;
      height: 100%;

      background-image: url('../img/header/header-steam-bg.webp');
      background-size: 100% 100%;
      background-repeat: no-repeat;

      right: 0;
      top: 0;

      pointer-events: none;

      opacity: .75;
    }
    &__text {
      color: rgba(var(--clr-w), 1);

      text-transform: uppercase;
      font-size: var(--t-base);
      font-weight: 800;

      position: relative;

      @include hide-text(1);
    }

    svg {
      fill: rgba(var(--clr-r), 1);

      filter: drop-shadow(0 0 .75rem rgba(var(--clr-r), 1));

      flex: 0 0 auto;

      width: 2rem;
      height: 1.25rem;
    }
  }
  &-body {
    @include dfc;
    flex-direction: column;
    grid-gap: 30px;
    &__logo {
      max-width: var(--logo-w);
      width: 100%;

      transition: filter 400ms ease;

      transform: translateY(calc(72px / -5));

      @include hover {
        filter: brightness(120%);
      }
    }

    &__floor {
      position: relative;

      width: 100%;
      &--flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &--grid {
        display: grid;
        grid-template-columns: minmax(var(--logo-w), 120px) auto minmax(264px, 200px);
        align-items: center;
        grid-gap: 40px;

        padding: 0 $root-inner-px;

        background-color: rgba(var(--clr-dark-8), 1);

        min-height: var(--header-h);

        position: relative;
        &::before {
          @include pseudo;

          top: 0;
          left: 0;

          width: 45%;
          height: 100%;

          background-image: url('../img/header/header-overlay.webp');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center;

          pointer-events: none;

          opacity: .25;
        }
      }
    }
  }
}

@include laptop {
  .header {
    overflow: visible;
    &-body {
      grid-gap: .75rem;

      &__logo {
        padding-left: 1rem;
        transform: translateY(-7px);
      }
    }
    &-row {
      width: 100%;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: auto 1fr auto auto;
      grid-template-areas:
      'lang ... btn socials';
    }
    &-support {
      grid-area: btn;
      padding: 0;

      width: 24px;
      height: 24px;

      border-radius: 2px;

      box-shadow: 0 0 0 1px rgba(var(--clr-w), .1);

      &__icon {
        width: 1rem;
        height: 1rem;
      }
      &__text {
        display: none;
      }
    }

    &-nav {
      position: fixed;

      right: 1rem;
      bottom: 74px;

      z-index: 4;

      opacity: 0;

      transition: opacity 200ms ease, all 300ms ease;

      pointer-events: none;

      transform: scale(.95);

      background-color: rgba(var(--clr-dark-7), .9);

      backdrop-filter: blur(4px);

      border-radius: 3px;

      border: 1px solid rgba(var(--clr-r), .5);

      padding: .5rem;

      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 280px;
      min-height: 120px;

      &--active {
        opacity: 1;
        transform: scale(1);
        pointer-events: all;
      }

      &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8px;
        justify-content: space-between;

        flex: 1;
      }
      &__item {
        padding: 8px;

      }
      &__link {
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        width: 100%;
        padding: 0;
        justify-content: center;

        color: rgba(var(--clr-w), 1);

        @include dfc;
      }
    }
    &-steam {
      padding: 12px 14px;
      &::after {
        display: none;
      }
      &__text {
        display: none;
      }
    }
    &-body {
      &__logo {
        max-width: 100px;
      }
      &__floor {
        &--grid {
          padding: 0;
          min-height: 46px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}

@include small-tablet {
  .header-nav {
    &__link {
      font-size: 11px;
    }
  }
}
