.giveaway {
  --bg-item: #{HexToRGB(#1c1723)};
  width: 100%;

  margin: 0 auto clamp(50px, 4vw, 64px);
  &__container {
    max-width: calc(1714px + (var(--container-offset) * 2));
  }
  &-header {
    flex: 1;

    &__title {
      font-size: 22px;
      font-weight: 800;
      text-transform: uppercase;
      color: rgba(var(--clr-w), 1);

      margin-bottom: .5rem;
    }
  }
  &-text {
    font-size: clamp(var(--t-sm), 1vw, var(--t-base));
    font-weight: 500;
    line-height: 140%;
    color: rgba(var(--clr-dark-3), 1);
  }
  &-grid {
    display: flex;
    align-items: stretch;
    grid-gap: 1.75rem clamp(20px, 2.75vw, 44px);

    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &__content {
      position: relative;

      display: flex;
      flex-direction: column;
      grid-gap: 1rem;
    }
    &__name {
      color: #fffeff;
      font-weight: 700;
      font-size: var(--t-base);
      text-transform: uppercase;

      margin-bottom: 5px;
    }
    &__sub {
      color: #4e465a;
      font-size: clamp(var(--t-xs), 0.875vw, var(--t-sm));
    }
    &__icon {
      position: relative;
    }
    &__item {
      --bg-current: var(--clr-ppl);

      min-height: 164px;

      background: linear-gradient(to right, rgba(var(--bg-current), .1) 0%, rgba(var(--bg-item), 1) 60%);
      border-left: 3px solid rgba(var(--bg-current), 1);

      &:nth-child(1) {
        flex: 0 0 auto;
        background: none;
        border: none;

        max-width: 352px;
      }
      &:not(:first-child) {
        flex: 1 0 auto;
      }
      &--flex {
        display: flex;
        flex-direction: column;
      }
      &--ppl {
        --bg-current: var(--clr-ppl);
      }
      &--red {
        --bg-current: 215, 8, 122;
      }
      &--grey {
        --bg-current: #{HexToRGB(#5e6072)};
      }
      &--padding {
        border-radius: 4px;

        padding: 26px 20px 26px clamp(28px, 2.375vw, 38px);

        position: relative;

        box-shadow: 0px 6px 6px 5px rgba(var(--clr-b), .1);

        display: grid;
        grid-template-columns: .25fr 1fr;
        align-items: center;
        grid-gap: clamp(20px, 1.625vw, 26px);

        &::before {
          @include pseudo;

          width: 100%;
          height: 100%;

          background-image: url('../img/giveaway/giveaway-item-overlay.webp');
          background-size: 100% 100%;
          background-repeat: no-repeat;

          top: 0;
          left: 0;

          opacity: .2;

          border-radius: inherit;

          pointer-events: none;
        }
      }
    }
    &__data {
      font-weight: 600;
      font-size: clamp(var(--t-sm), 1vw, var(--t-base));
      letter-spacing: -0.04px;

      @include hide-text(1);

      position: relative;

      &::after {
        @include pseudo;

        height: 100%;
        width: 100%;

        top: 0;
        left: 0;

        background-image: linear-gradient(to left, rgba(var(--bg-item), 1) 0%, transparent);
      }
      span {
        color: rgba(var(--clr-dark-3), 1);
      }
    }
    &__time {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      &-icon {
        svg {
          width: 1.125rem;
          height: 1.125rem;

          fill: rgba(var(--clr-r), 1);

          display: block;

          position: relative;

        }
        position: relative;

        &::after {
          @include pseudo;

          width: 40px;
          height: 40px;

          border-radius: 50%;

          background-color: rgba(var(--clr-r-2), .25);

          top: 50%;
          left: 50%;

          transform: translate(-50%, -50%);

          filter: blur(8px);

          pointer-events: none;
        }
      }
      &-text {
        color: rgba(var(--clr-w), 1);
        font-size: 15px;
        font-weight: 700;
      }
    }
    &__icon {
      width: 100px;
      height: 74px;

      img {
        width: inherit;
        height: inherit;
        display: block;

        object-fit: contain;
      }
    }
  }
  &-winners {
    display: flex;
    justify-content: center;
    grid-gap: 12px;

    background-color: #231c27;

    border-radius: 4px;

    padding: 1.125rem 1.25rem;

    width: fit-content;

    &__icon {
      svg {
        display: block;

        width: 32px;
        height: 24px;

        fill: rgba(var(--clr-r), 1);


      }
    }
    &__text {
      color: rgba(var(--clr-w), 1);
      font-weight: 600;
      font-size: clamp(var(--t-sm), 1vw, var(--t-base));
    }
  }
}

@include mobile {
  .giveaway {
    &__container {
      margin-right: -16px;
    }
    &-winners {
      width: 100%;
    }
    &-grid {
      &__item {
        padding: 1.125rem;

        &:nth-child(1) {
          padding: 0;
          max-width: 270px;
        }
      }
    }
  }
}
