.range {
  position: relative;
  height: 5px;
  width: 228px;

  &-label {
    position: absolute;

    font-size: var(--t-sm);
    font-weight: 600;
    color: rgba(var(--clr-w), 1);

    top: -24px;

    &--left {
      left: 0;
    }
    &--right {
      right: 0;
    }
  }
  &-track {
    background: linear-gradient(to right, rgba(var(--bg-range), 1) 30%, rgba(var(--clr-r), 1) 30%, rgba(var(--clr-y), 1) 80%, rgba(var(--bg-range), 1) 80%);

    position: absolute;

    width: 100%;
    height: 100%;

    z-index: 1;

    &--blue {
      // background: linear-gradient(to right, rgba(var(--bg-range), 1) 30%, #0690c1 30%, #0690c1 76%, rgba(var(--bg-range), 1) 76%) !important;
    }
  }
  input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 5px;
  }
  input[type="range"]::-moz-range-track {
    -moz-appearance: none;
    height: 5px;
  }
  input[type="range"]::-ms-track {
    appearance: none;
    height: 5px;
  }
  &-slider {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-appearance: none;
    margin: 0;

    width: 100%;
    height: 100%;

    background-color: rgba(var(--bg-range));

    border-radius: 4px;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;

      width: 1.125rem;
      height: 1.125rem;

      top: -10px;
      z-index: 1;

      transition: 300ms ease;
      background-image: url('../img/range/range-thumb.webp');
      border-radius: 6px;
      background-size: 100% 100%;

      position: relative;

      cursor: pointer;

      margin: 4px;

      @include hover {
        box-shadow: 0 0 0px 4px rgba(var(--clr-y), .5);
      }
      &:active {
        box-shadow: 0 0 0 6px rgba(var(--clr-y), .5);
      }
    }
  }
}

@include mobile {
  .range {
    width: 100%;
  }
}
